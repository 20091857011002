.contacts__container {
    margin-top: 85px;
    width: 100%;
    position: relative;
}

.contacts__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
}