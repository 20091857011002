.footer{
    position: relative;
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    background-color: #4b4a4a;
}
.footer__container{
    width: 95%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    overflow: hidden;
    flex-wrap: wrap;
    color: white;
}
.footer__container p{
    margin-top: 5px;
}

.footer__contacts, .footer__grafic, .footer__rights{
    width: 250px;
    margin: 10px;
}

.footer__logo{
    width: 230px;
    height: 85px;
}
.footer__logo img{
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
@media (max-width: 950px) {
    .footer__container{

        justify-content: center;
        align-items: center;
        flex: 0 0 100%;
    }
}
