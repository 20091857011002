.Registration{
    margin-top:  90px;
}
.Registration__container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Registration__container input{
   border: 1px solid black;
    padding: 5px;
    margin: 5px 0 20px 0;
    width: 25%;
}
.Registration__button{
    cursor: pointer;
    margin: 20px 0;
}
.modal{
    position: absolute;
    top: 40%;
    left: 25%;
    width: 50%;
    height: fit-content;
    color: azure;
    background-color: rgb(0, 0, 0, 0.7);
    padding: 30px 20px;
    text-align: center;
    animation: scaleanim 1s;
    z-index: 12;
}
.modal p{
    margin: 7px 0;
}
.modal button{
    padding: 5px 20px;
    cursor: pointer;
}
@keyframes scaleanim {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
    }