
.Table__content{
    
    margin-top: 90px;
    position: relative;
    width: 100%;
}
.Table__content table{
	width: 100%;
	border: 1px solid grey;
	text-align: center;
	border-collapse:collapse;
}
.Table__content table tr,td,th {
	border: 1px solid grey;
	padding: 5px;
}
.Table__button{
    padding: 5px;
    cursor: pointer;
	background-color: rgb(214, 213, 213);
}
.countUsers{
	margin: 10px;
	font-size: 20px;
}