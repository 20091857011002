
.MainPage__foto{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
}
.MainPage__foto img{
    display: block;
    width: 100%;
    height: 100%;
}
.MainPage__foto:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    background-color: rgba(0,0,0,0.7);
  }
.MainPage__content{
    width: 100vw;
    height: 100%;
}
.MainPage__content img{
    display: block;
    width: 100%;
    height: 100%;
}
/* slider ___________________________________________________________________________________*/
.MainPage__slider{
    position: absolute;
    top: 30%;
    left: 100px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.MainPage__slider img{
    width: 600px;
    height: 350px;
}
.MainPage__slider p{
    color: white;
}
.MainPage__sliderText{
    animation: animLeft 3s;
}
.MainPage__sliderImg{
    animation: animRight 3s;
}

.Slider__button{
    padding: 10px 15px;
    font-size: 18px;
    font-weight: 400;
    background: rgba(0,0,0,0.3);
    color: white;
    border: solid 2px white;
    margin-top: 15px;
    cursor: pointer;
    transition: all 0.5s ease 0s;
}
.Slider__button:hover{
    background: white;
    color: black;

}
.Slider__button:active{
    background: white;
    color: black;
}

.slider__topText{
    font-size: 32px;
}
.slider__bottomText{
    font-size: 40px;
    font-weight: 600;
}

@keyframes animLeft {
    from {
        transform: translateX(-400px);
    }
    to {
        transform: translateX(0);
    }
    }
    @keyframes animRight {
        from {
            transform: translateX(600px);
        }
        to {
            transform: translateX(0);
        }
        }

/* categories ___________________________________________________________________________________*/
.MainPage__categories{
    position: relative;
    width: 100%;
    height: 100%;
}
.MainPage__categories h1{
    text-align: center;
    font-weight: 400;
    margin: 60px 0;
    overflow: hidden;
}

.MainPage__categories h1:before,
.MainPage__categories h1:after {
	content: '';
	display: inline-block;
	vertical-align: middle;
	box-sizing: border-box;
	width: 100%;
	height: 1px;
	background: #000000;
	border: solid rgb(225, 247, 252);
	border-width: 0 20px;
}
.MainPage__categories h1:before {
	margin-left: -100%;
}
.MainPage__categories h1:after {
	margin-right: -100%;
}

.Categories__conteiner{
    margin: 90px 10px 100px 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}
.Categories__item{
    width: 23%;
    min-width: 160px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease 0s;
    padding: 25px;
    background-color: rgb(241, 241, 241);
    border-radius: 5px;
    margin: 0 1% 1% 1%;
}

.Categories__item:hover{
    box-shadow: 0px 0px 5px 2px rgb(6, 52, 255);
}
.Categories__item:active{
    box-shadow: 0px 0px 5px 2px rgb(6, 52, 255);
}
.Categories__itemImg{
    width: 150px;
    height: 125px;
    transition: all 0.5s ease 0s;
}
.Categories__itemImg:hover{
    transform: rotateY(180deg);
}
.Categories__itemImg img{
    display: block;
    width: 100%;
    height: 100%;
}
.Categories__itemName{
    font-size: 16px;
    margin: 7px 0px;
    text-align: center;
}
/* Industry ___________________________________________________________________________________*/

.MainPage__industry{
    position: relative;
    width: 100%;
    height: 100%;
}
.Industry__img{
    position: relative;
    width: 100%;
    height: 100vh;
}
.Industry__img:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    background-color: rgba(0,0,0,0.7);
  }
.Industry__img img{
    display: block;
    width: 100%;
    height: 100%;
}
.Industry__content{
    position: absolute;
    top: 30%;
    left: 100px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}
.Industry__topText{
    font-size: 32px;
}
.Industry__bottomText{
    font-size: 40px;
    font-weight: 600;
}
.Industry__contentImg img{
    width: 400px;
    height: 350px;
}
.Industry__textInvisible{

    opacity: 0;
}

.Industry__text{

    opacity: 1;
    animation: animLeft 2s;
}

.Industry__contentImgInvisible{
    opacity: 0;
}
.Industry__contentImg{
    opacity: 1;
    animation: animRight 2s;
}
/* Advantage ___________________________________________________________________________________*/

.Advantage__container{
    margin: 0 10px 60px 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}
.Advantage__item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background-color: rgb(241, 241, 241);
}
.Advantage__itemImg{
    width: 150px;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Advantage__itemImg img{
    display: block;
    width: 50%;
    height: 50%;
}
#itemImg1{
    display: block;
    width: 35%;
    height: 50%;
}
.Advantage__textTop{
    font-size: 30px;
    font-weight: 600;
}
.Advantage__textBottom{
    margin-top: 5px;
}

.MainPage__advantage h1{
    text-align: center;
    font-weight: 400;
    margin-top: 60px;
    overflow: hidden;
}

.MainPage__advantage h1:before,
.MainPage__advantage h1:after {
	content: '';
	display: inline-block;
	vertical-align: middle;
	box-sizing: border-box;
	width: 100%;
	height: 1px;
	background: #000000;
	border: solid rgb(225, 247, 252);
	border-width: 0 20px;
}
.MainPage__advantage h1:before {
	margin-left: -100%;
}
.MainPage__advantage h1:after {
	margin-right: -100%;
}


/* media __________________________________________________________________________________________*/
@media (max-width: 945px) {
    .MainPage__slider{
        position: absolute;
        top: 25%;
        left: 20px;
        width: 90%;
        flex-direction: column;
    }
    .Industry__content{
        position: absolute;
        top: 25%;
        left: 20px;
        width: 90%;
        flex-direction: column;
    }
    .Categories__item,.Advantage__item{
        margin: 10px;
    }
    .Categories__conteiner {
        justify-content: center;
    }

    .Advantage__container{
        justify-content: center;
    }

}

@media (max-width: 650px) {
    .MainPage__foto{
        overflow: hidden;
    }
    .MainPage__foto img{
        width: 250%;
        height: 100%;
    }
    .MainPage__slider img{
        width: 350px;
        height: 210px;
        margin-top: 60px;
    }
    .Industry__contentImg img{
        width: 350px;
        height: 290px;
        margin-top: 60px;
    }
    .Categories__item{
        width: 150px;
    }
    .Categories__itemImg{
        width: 100px;
        height: 75px;
    }
    .MainPage__industry{
        overflow: hidden;
    }
}
