
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
	padding: 0;
	margin: 0;
	border: 0;
}
html,body{
	height: 100%;
	width: 100%;
	line-height: 1;
	font-size: 14px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}
#root {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-x: hidden;
  }
